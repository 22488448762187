import { formatTimeAgo } from '@vueuse/core';
import { date } from 'quasar';

const { formatDate } = date;

/**
 * @see https://vueuse.org/core/useTimeAgo/
 */
const relative = (value?: string | number | Date) => {
  const date
    = value instanceof Date ? value : new Date(value as string | number);

  return formatTimeAgo(date, {
    fullDateFormatter: v => formatDate(v, 'M/D/YYYY'),
    max: 'month',
  });
};

export const useDateFormat = () => {
  return {
    format: formatDate,
    datetime: (value?: string | number | Date) =>
      formatDate(value, 'M/D/YYYY h:mm:ss A'),
    date: (value?: string | number | Date) => formatDate(value, 'M/D/YYYY'),
    time: (value?: string | number | Date) => formatDate(value, 'h:mm:ss A'),
    relative,
  };
};
